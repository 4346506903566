// import "./style.css";
// import { Application } from "@splinetool/runtime"
import { createClient } from "@supabase/supabase-js";

// Globals
let currentState = "Main";
let hasPlayed = false;
let currentCount = 0;
const counterCircle = document.querySelector(".counter");

// Spline Setup
const canvas = document.getElementById("canvas3d");
let splineApp = null;
const loadSpline = async () => {
  const splineRuntime = await import("@splinetool/runtime");
  splineApp = new splineRuntime.Application(canvas);
  const mobile_cube =
    "https://prod.spline.design/VnDi7MrYXv0RSwoL/scene.splinecode";
  const desktop_cube =
    "https://prod.spline.design/DovPmiO2vZ4AaEX3/scene.splinecode";
  let cubeUrl = desktop_cube;
  if (navigator.userAgent.indexOf("Mobi") > -1) {
    cubeUrl = mobile_cube;
  }
  splineApp.load(cubeUrl).then(() => {
    const loading = document.querySelector(".loading");
    loading.classList.add("fade-me-out");
  });
};

// Supabase Setup
const supabaseUrl = "https://zbfbxxxuxeaeaqptwxer.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpiZmJ4eHh1eGVhZWFxcHR3eGVyIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTA2ODI4MjMsImV4cCI6MTk2NjI1ODgyM30.HCe5Efu3vD0E_QMvpxNx6tRyveyeKA7MpgMJwMkutrI";

const supabase = createClient(supabaseUrl, supabaseAnonKey);
const counterSubscription = supabase
  .from("counter:counter_name=eq.tobi_count")
  .on("UPDATE", (payload) => {
    currentCount = payload.new.current_count;
    counterCircle.innerText = currentCount;
  })
  .subscribe();

supabase
  .from("counter")
  .select("current_count")
  .eq("counter_name", "tobi_count")
  .then((res) => {
    currentCount = res.data[0].current_count;
    counterCircle.innerText = currentCount;
  });

// Main
const onStateChange = (currentState, state) => {
  if (!splineApp) return;
  splineApp.emitEvent("mouseDown", `Button_Cube_Close_${currentState}`);
  splineApp.emitEvent("mouseDown", `Button_Cube_Open_${state}`);
};

const updateCounter = async () => {
  const res = await supabase.rpc("increment_counter", {
    counter_name: "tobi_count",
  });
  console.log("Counter updated", res);
};
const musicButton = document.querySelector(".musicbtn");
musicButton.addEventListener("click", () => {
  onStateChange(currentState, "Music");
  currentState = "Music";
});

const videoButton = document.querySelector(".videobtn");
videoButton.addEventListener("click", () => {
  onStateChange(currentState, "Video");
  currentState = "Video";
});

const homeButton1 = document.querySelector(".logo-top");
homeButton1.addEventListener("click", () => {
  onStateChange(currentState, "Main");
  currentState = "Main";
});

const musicPlayer = document.getElementById("music");
const musicPlayerText = document.getElementById("move-me");
var musicPlaying = false;
const musicPlayerButton = document.querySelector(".music-button-r");
musicPlayerButton.addEventListener("click", () => {
  document.getElementById("pre-counter").innerText = "YOU ARE LISTENER #";
  document.getElementById("post-counter").innerText = "";
  document.getElementById("move-me").classList.toggle("move-anim");
  document.getElementById("sound-on").classList.toggle("hidden");
  document.getElementById("sound-off").classList.toggle("hidden");
  document.getElementById("speaker-svg").classList.toggle("rotate-once");

  if (musicPlaying == false) {
    musicPlayer.play();
    musicPlaying = true;
    musicPlayerText.innerText = "♫ BEFORE WE PANIC ♫";
    if (!hasPlayed) {
      updateCounter();
      hasPlayed = true;
    }
  } else if (musicPlaying == true) {
    musicPlayer.pause();
    musicPlaying = false;
    musicPlayerText.innerText = "♫ BEFORE WE PANIC ♫";
  }
});
loadSpline();

const cube_instructions = document.querySelector(".instructions");
const cube_canvas = document.querySelector(".canvascontainer");
cube_canvas.addEventListener("mousedown", () => {
  cube_instructions.classList.add("fade-me-out");
});
cube_canvas.addEventListener("touchstart", () => {
  cube_instructions.classList.add("fade-me-out");
});
